import { merge, restore, sample } from 'effector'
import * as smApi from '@gmini/sm-api-sdk'

import * as gStationApi from '@gmini/sm-api-sdk/lib/GStationApi/DocumentManagement'

import { currentProjectAssembly$ } from './project'

const dependenciesWithModels = merge([
  smApi.DependencyWithModels.getClassifierDependencyModels.doneData,
  smApi.DependencyWithModels.getAssemblyDependencyModels.doneData,
])

sample({
  clock: dependenciesWithModels,
  source: currentProjectAssembly$,
  fn: (project, { dependencyModels }) => {
    if (!project || project.sourceType === 'Bim360') {
      return null
    }
    const req: gStationApi.DocumentManagement.GetDerivativeReq['files'] = []
    dependencyModels.forEach(({ models }) => {
      models.forEach(m => {
        m.viewerRefs.forEach(ref => {
          req.push({ urn: ref.urn, version: `${ref.version}` })
        })
      })
    })
    return { req, project }
  },
}).watch(data => {
  if (data?.req.length) {
    gStationApi.DocumentManagement.fetchModelDerivative.defaultContext.submit({
      files: data.req,
      projectId: data.project.id,
    })
  }
})

export const viewerDerivatives$ = restore(
  gStationApi.DocumentManagement.fetchModelDerivative.defaultContext.doneData,
  null,
)
