import { combine, forward, Event } from 'effector'

import * as React from 'react'
import { LinearProgress } from '@gmini/ui-kit/lib/LinearProgress'
import { useHistory } from 'react-router-dom'

import { prop } from 'ramda'

import { useStore } from 'effector-react'

import { ActiveItemRefs } from '@gmini/ui-kit/lib/MacosExplorer'

import {
  Folder,
  CheckupFilled,
  FolderEmpty,
  AssemblyFilled,
} from '@gmini/ui-kit/lib/icons'

import * as smApi from '@gmini/sm-api-sdk'

import {
  Explorer,
  Root,
  Item,
  Assembly,
  Entity,
} from '@gmini/common/lib/Explorer'

import {
  useCopy,
  useMove,
  useRemove,
  useRename,
  useCreate,
} from '@gmini/common/lib/Explorer/hooks'

import {
  fromEntityTreeData,
  openNewAssemblyForm,
} from '@gmini/common/lib/Explorer/Model'

import { createTreeModel } from '@gmini/common/lib/Explorer/Model/createTreeModel'

import { baseExplorerOperations } from '@gmini/common/lib/Explorer/ctx/baseOperations'

import { usePersist } from '@gmini/common/lib/Explorer/hooks/usePersist'

import {
  preloadPending$,
  resetPreloadPending,
} from '@gmini/common/lib/Explorer/Model/tree.persist'

import { useActivePanels } from '@gmini/common/lib/Explorer/hooks/useActivePanels'

import { AssemblyCtxIcon } from '@gmini/common/lib/Explorer/ctx/icons'

import { useRemoveDialog } from '@gmini/common/lib/Explorer/hooks/useRemoveDialog'

import { isNotEmpty, or } from '@gmini/utils'

import { useContextMenu } from '@gmini/common/lib/components/VersionSwitch/ContextMenu'

import { Index } from '@gmini/normalized-tree'

import { node as n } from '@gmini/common/lib/Services/UserClassifierRepoService/'

import {
  AssemblyClassifierNode,
  UserClassifierNode,
} from '@gmini/common/lib/Services/UserClassifierRepoService/Node'

import { notificationService } from '../../services/notificationService'

import { userClassifierRepoService } from '../../services/userClassifierRepoServiceConnect'

import { tree$, fetchChildren, reset } from './Model'
import { explorerTitle, EXPLORER_FOLDER_OPENED_KEY } from './constants'

forward({
  from: notificationService.message.filter({ fn: smApi.NotificationEvent.is }),
  to: userClassifierRepoService.notification,
})

const { subscriptions } = notificationService

const created: Event<
  Exclude<
    smApi.NotificationEvent.Create.Payload,
    smApi.BimElementReferenceItems
  >
> = notificationService.message
  .filter({ fn: smApi.NotificationEvent.is })
  .filter({ fn: smApi.NotificationEvent.Create.is })
  .map(prop('payload'))
  .filter({
    fn: or<
      Exclude<
        smApi.NotificationEvent.Create.Payload,
        smApi.BimElementReferenceItems
      >,
      | smApi.UserClassifierRepositoryFolder
      | smApi.UserClassifier
      | smApi.UserClassifierGroup
      | smApi.BimReference
      | smApi.AssemblyClassifier
    >(
      smApi.UserClassifierRepositoryFolder.is,
      smApi.UserClassifier.is,
      smApi.UserClassifierGroup.is,
      smApi.BimReference.is,
      smApi.AssemblyClassifier.is,
    ),
  })

const folderCreated = created.filter({
  fn: smApi.UserClassifierRepositoryFolder.is,
})

const classifierCreated = created.filter({ fn: smApi.UserClassifier.is })
const assemblyCreated = created.filter({ fn: smApi.AssemblyClassifier.is })

const { root$ } = createTreeModel({
  tree$,
  explorerTitle,
  categoriesOnTop: true,
})

const pending$ = combine(
  [smApi.UserClassifierRepo.fetch.defaultContext.pending$, preloadPending$],
  pendingList => pendingList.some(Boolean),
)

const operationsPending$ = combine(
  [
    smApi.UserClassifier.move.defaultContext.pending$,
    smApi.UserClassifier.remove.defaultContext.pending$,
    smApi.UserClassifier.create.defaultContext.pending$,
    smApi.UserClassifier.copy.defaultContext.pending$,
    smApi.UserClassifier.fetch.defaultContext.pending$,
    smApi.UserClassifierRepoFolder.rename.defaultContext.pending$,
    smApi.UserClassifierRepoFolder.move.defaultContext.pending$,
    smApi.UserClassifierRepoFolder.create.defaultContext.pending$,
    smApi.UserClassifierRepoFolder.remove.defaultContext.pending$,
    smApi.UserClassifierRepoFolder.fetch.defaultContext.pending$,
    smApi.AssemblyClassifier.rename.defaultContext.pending$,
    smApi.AssemblyClassifier.move.defaultContext.pending$,
    smApi.AssemblyClassifier.create.defaultContext.pending$,
    smApi.AssemblyClassifier.remove.defaultContext.pending$,
    smApi.AssemblyClassifier.fetch.defaultContext.pending$,
    smApi.Project.fetchList.defaultContext.pending$,
  ],
  pendingList => pendingList.some(Boolean),
)

export const SetManagementExplorer = React.memo<{
  onOpen(options: smApi.AssemblyClassifier | smApi.UserClassifier): void
  panelsCount: (count: number) => void
  panelWidth: number
  selectedProject: smApi.Project | null
}>(({ onOpen, panelWidth, panelsCount, selectedProject }) => {
  const history = useHistory()

  const root = useStore(root$)
  const pending = useStore(pending$)
  const operationsPending = useStore(operationsPending$)
  const selectedProjectUrn = selectedProject?.urn
  const persistKey = `${EXPLORER_FOLDER_OPENED_KEY}__${
    selectedProjectUrn || ''
  }`

  const tree = React.useMemo(
    () =>
      fromEntityTreeData({
        parentNode: root.reduce(
          (acc, next) => (next ? { ...acc, ...next } : acc),
          {} as Root,
        ),
        source: root.flatMap(root => root.children),
      }),
    [root],
  )

  const { activePanels, setActive, setActivePanels } = useActivePanels()

  const { toPersist, initTree } = usePersist({
    key: persistKey,
    tree,
    fetchChildren: smApi.UserClassifierRepoFolder.fetchSilent.defaultContext,
  })

  React.useEffect(() => {
    if (activePanels.length) {
      panelsCount(activePanels.length)
      toPersist({ activeItemRefs: activePanels })
    }
  }, [activePanels, panelsCount, toPersist])

  React.useEffect(() => {
    if (selectedProjectUrn) {
      setTimeout(() => {
        smApi.UserClassifierRepo.fetch.defaultContext.submit({
          projectUrn: selectedProjectUrn,
        })
      }, 0) //TODO Костыль для того чтобы токен успел засетиться в стор перед запросом
    }

    // Дожидаемся загрузки данных, для работы с деревом в initTree
    const subscription = smApi.UserClassifierRepo.fetch.defaultContext.done.watch(
      () => {
        initTree(tree$).then(active => {
          setActivePanels(active)
        })
      },
    )
    return () => {
      subscription.unsubscribe()
      resetPreloadPending()
    }
  }, [initTree, selectedProjectUrn, setActivePanels])

  React.useEffect(() => () => reset(), [])

  const nodes = useStore(userClassifierRepoService.nodes$)

  const { onCreate } = useCreate({
    entityCreated: classifierCreated,
    folderCreated,
    assemblyCreated,
    onCreateAssembly: smApi.AssemblyClassifier.create.defaultContext,
    onCreateEntity: smApi.UserClassifier.create.defaultContext,
    onCreateFolder: smApi.UserClassifierRepoFolder.create.defaultContext,
    onOpen,
  })

  const getAssembly = React.useCallback((nodes: Index<n.Node>, id: number) => {
    const nodesArray = Object.values(nodes).filter(isNotEmpty)

    const assemblyClassifierNodes = nodesArray.filter(AssemblyClassifierNode.is)

    return assemblyClassifierNodes.find(classifier => classifier.id === id)
  }, [])

  const getEntity = React.useCallback((nodes: Index<n.Node>, id: number) => {
    const nodesArray = Object.values(nodes).filter(isNotEmpty)

    const userClassifierNodes = nodesArray.filter(UserClassifierNode.is)

    return userClassifierNodes.find(classifier => classifier.id === id)
  }, [])

  const { onCopy } = useCopy({
    getEntity: id => getEntity(nodes, id),
    onCopyEntity: entity => smApi.UserClassifier.copy.defaultContext(entity),
    getAssembly: id => getAssembly(nodes, id),
    onCopyAssembly: smApi.AssemblyClassifier.copy.defaultContext,
  })

  const { onRename } = useRename({
    onRenameFolder: smApi.UserClassifierRepositoryFolder.rename.defaultContext,
    onRenameEntity: smApi.UserClassifier.rename.defaultContext,
    onRenameAssembly: smApi.AssemblyClassifier.rename.defaultContext,
  })

  const { onMove } = useMove({
    onMoveFolder: smApi.UserClassifierRepositoryFolder.move.defaultContext,
    onMoveEntity: smApi.UserClassifier.move.defaultContext,
    onMoveAssembly: smApi.AssemblyClassifier.move.defaultContext,
    fetch: smApi.UserClassifierRepositoryFolder.fetch.defaultContext,
  })

  const { onRemove } = useRemove({
    onRemoveFolder: ({ id }) => {
      setActivePanels(
        activePanels.filter(activeItemRef => {
          const [, activeItemRefId] = activeItemRef.split(':')

          return !(Number(activeItemRefId) === id)
        }),
      )
      return smApi.UserClassifierRepositoryFolder.remove.defaultContext({ id })
    },
    onRemoveAssembly: smApi.AssemblyClassifier.remove.defaultContext,
    onRemoveEntity: smApi.UserClassifier.remove.defaultContext,
  })

  const onEntityOpen = React.useCallback(
    (id: number, type: Assembly['type'] | Entity['type']) => {
      if (type === 'Assembly') {
        history.push(`/assembly-classifier/${id}`)
      } else {
        history.push(`/user-classifiers/${id}`)
      }
    },
    [history],
  )

  React.useEffect(() => {
    subscriptions.subscribeExplorer({ type: 'ClassifierRepository' })

    return () => {
      subscriptions.unsubscribeExplorer({ type: 'ClassifierRepository' })
    }
  }, [])

  const setActiveItem = React.useCallback(
    (activeItems: ActiveItemRefs) => {
      if (tree.length) {
        setActive(activeItems, tree)
      }
    },
    [setActive, tree],
  )

  const { RemoveDialog, setToRemoveNode } = useRemoveDialog(onRemove)

  const baseOperations = baseExplorerOperations({
    onRemoveItem: (item: Item) => {
      if (item.type === 'Assembly' || item.type === 'Entity') {
        setToRemoveNode(item)
        return
      }
      onRemove(item)
    },
    createEntityTitle: 'Создать классификатор',
  })

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    node: Item | null
    onOpen?: () => void
  }>(
    baseOperations.reduce(
      (acc: typeof baseOperations, operation) =>
        operation.title === 'Создать классификатор'
          ? [
              ...acc,
              operation,
              {
                title: 'Создать сборку',
                onClick: ({ node, onOpen }) => {
                  if (onOpen) {
                    onOpen()
                  }
                  openNewAssemblyForm({
                    parentCategoryId: node?.id ? node.id : undefined,
                  })
                },
                customIcon: AssemblyCtxIcon,
                show: ({ node }) => !node || node.type === 'Category',
              },
            ]
          : [...acc, operation],
      [],
    ),
  )

  return (
    <>
      <userClassifierRepoService.Gate />

      {pending ? (
        <LinearProgress />
      ) : (
        <>
          {RemoveDialog}
          <Explorer
            entityIcon={<CheckupFilled />}
            categoryIcon={<Folder />}
            categoryEmptyIcon={<FolderEmpty />}
            assemblyIcon={<AssemblyFilled />}
            data={tree}
            onCreate={onCreate}
            onRename={onRename}
            onCopy={onCopy}
            onMove={onMove}
            onRemove={onRemove}
            onEntityOpen={onEntityOpen}
            setActiveItems={setActiveItem}
            activeItems={activePanels}
            fetchChildren={fetchChildren}
            pending={pending}
            panelWidth={panelWidth}
            ContextMenu={ContextMenu}
            setCtxMenu={setCtxMenu}
            ctxMenu={ctxMenu}
            hideCtxMenu={operationsPending}
            selectedProject={selectedProject}
          />
        </>
      )}
    </>
  )
})

SetManagementExplorer.displayName = 'SetManagementExplorer'
