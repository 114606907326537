import React from 'react'
import { Box } from '@gmini/ui-kit/lib/Box'

import { combine, createEvent, merge, sample } from 'effector'
import { prop } from 'ramda'

import { useStore } from 'effector-react'

import { useKeycloak } from '@react-keycloak/web'

import {
  HeaderLogoWrap,
  Logo,
  Resizable,
  ResizablePanel,
  IconButton,
  PlusCircle,
  SeparatorList,
  Tooltip,
  FolderWithPlus,
  Cover,
  Substrate,
  WithCursorIcon,
} from '@gmini/ui-kit'
import { Header } from '@gmini/ui-kit/lib/Header/Header'

import { isNotEmpty } from '@gmini/utils'

import { UserClassifierNode } from '@gmini/common/lib/classifier-service/Node'

import { getNode } from '@gmini/common/lib/classifier-service'

import * as forgeViewer from '@gmini/common/lib/forge-viewer'

import * as filter from '@gmini/common/lib/classifier-editor/DependencyTree/Filters/InclusionFilter/FilterModel'

import { useInclusionFilter } from '@gmini/common/lib/classifier-editor/DependencyTree/Filters/InclusionFilter'

import { DependencyTreeWrap } from '@gmini/common/lib/classifier-editor/DependencyTree/DependencyTreeWrap'

import { createFetchDependenciesService } from '@gmini/common/lib/classifier-editor/DependencyTree/model'

import { createModelStoreService } from '@gmini/common/lib/classifier-editor/ModelSelect/modelStore'

import {
  getViewerId,
  validateModelTypes,
} from '@gmini/common/lib/classifier-editor/Common/utils'

import { adapter } from '@gmini/common/lib/classifier-service/adapters'

import { createExpandModel } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/expandModel'

import { createClassifiersTree } from '@gmini/common/lib/classifier-editor/SelectDependencies/ClassifiersTree/ClassifiersTree'

import { createModelsTree } from '@gmini/common/lib/classifier-editor/SelectDependencies/ModelsTree'

import { createVersionHistoryStore } from '@gmini/common/lib/components/VersionSwitch/versionHistoryStore'
import { getBimNode } from '@gmini/common/lib/classifier-editor/Search/utils'

import {
  compareDates,
  fromDateTimeToDate,
  sortByDate,
} from '@gmini/common/lib/components/VersionSwitch/common'

import { createUserClassifierRepoTreeModel } from '@gmini/common/lib/classifier-editor/SelectDependencies/ClassifiersTree/createTreeModel'

import {
  DragLayer,
  ModelSelect,
  openExplorer,
  SelectDependencies,
  isApiFlatNode,
  createSearchModel,
  createSelectDependenciesParams,
  createViewerDisplayModel,
  selectedModels as selectedModelsService,
} from '@gmini/common/lib/classifier-editor'

import { TreeLoader } from '@gmini/ui-kit/lib/TreeLoader/TreeLoader'
import { PageContentContainer, useNavbar } from '@gmini/common/lib/components'

import { createStatusPanel } from '@gmini/common/lib/classifier-editor/StatusPanel'
import { userInfo$ } from '@gmini/common/lib/auth/auth'
import {
  useReadonlyMode,
  VersionButton,
  VersionHistory,
  ProjectBadge,
  getModulesLinkFromEnv,
} from '@gmini/common'
import {
  useNamedVersions,
  useVersionName,
} from '@gmini/common/lib/components/VersionSwitch/NamedVersions'
import { useContextMenu } from '@gmini/common/lib/components/VersionSwitch/ContextMenu'
import { goals } from '@gmini/common/lib/metrika'
import { VersionList } from '@gmini/common/lib/components/VersionSwitch/VersionList'

import { createModelManageMenu } from '@gmini/common/lib/classifier-editor/ModelManageMenu' // TODO move to classifier editor

import * as smApi from '@gmini/sm-api-sdk'

import { fetchForgeToken } from '../../services/forgeService'

import {
  notificationService,
  resetBimFileIds,
} from '../../services/notificationService'

import { classifierService } from '../../services/userClassifierService'

import { currentUserClassifier$, projectUrn$ } from '../CurrentUserClassifier'

import { logEventEditor } from '../../amplitude'
import { envLinks } from '../../config'

import { userClassifierRepoService } from '../../services/userClassifierRepoServiceConnect'

import { viewerDerivatives$ } from './core/fetchViewerModelDerivatives'
import { currentProject$ } from './core/project'

import { dependencyTreeModel } from './model/dependencyTreeModel'

import {
  dependencyCheckedModel,
  editorCheckedModel,
} from './model/checkedModel'

import { EditorTreeWrap, treeModel } from './EditorTreeWrap/EditorTreeWrap'
import {
  fetchAllInclusion,
  filteredFlatTree$,
  inclusionStore$,
  loadOnceNodeInclusion,
} from './model/inclusionModel'

import { dependencyExpandModel } from './model/dependencyExpandModel'

import './core/init'
import { searchSourceModel } from './model/searchSourceModel'

import {
  BrandContainer,
  BrandTitle,
  BrandSecondaryWrapper,
} from './UserClassifierEditorPage.styled'

const inclusionStatusWithTranscript = {
  PARENT_INCLUDED: 'Родительский элемент добавлен в классификатор',
  SELF_INCLUDED: 'Элемент добавлен в классификатор',
  CHILDREN_INCLUDED: 'Один из дочерних элементов добавлен в классификатор',
}

const tree$ = combine(
  dependencyTreeModel.flatTree$,
  treeModel.flatTree$,
  (depTree, treeModel) => [...depTree, ...treeModel],
)

export const { Tags, searchModel } = createSearchModel({
  nodes$: classifierService.nodes$,
  currentEntity$: currentUserClassifier$,
  tree$,
})

const searchedBimNode$ = sample({
  source: classifierService.nodes$,
  clock: searchModel.searchNode$,
  fn: (nodes, searchedData) => {
    const node = searchedData?.node
    if (!node) {
      return null
    }

    const element = getBimNode(node, nodes)

    return element || null
  },
})

const { subscriptions, message } = notificationService
const notification = message.filter({ fn: smApi.NotificationEvent.is })

const {
  addedDependencyIds$,
  currentModelsByClassifierMap$,
} = createSelectDependenciesParams({
  nodes$: classifierService.nodes$,
  currentEntity$: currentUserClassifier$,
})

const userClassifierTree$ = createUserClassifierRepoTreeModel(
  userClassifierRepoService,
  projectUrn$,
)

const { ClassifiersTree } = createClassifiersTree({
  inclusionStatus$: inclusionStore$,
  addedDependencyIds$,
  currentUserClassifier$,
  tree$: userClassifierTree$,
})

const { ModelsTree, bimFile$ } = createModelsTree({
  addedDependencyIds$,
  inclusionStatus$: inclusionStore$,
  currentModelsByClassifierMap$,
  currentEntity$: currentUserClassifier$,
  notification,
})

const { ModelManageMenu } = createModelManageMenu({
  classifierService,
  currentEntity$: currentUserClassifier$,
  bimFile$,
})

export const { StatusPanel: DependenciesStatusPanel } = createStatusPanel({
  checkedModel: dependencyCheckedModel,
  flatTree$: dependencyTreeModel.flatTree$,
  searchNodeInfo$: searchModel.searchNode$,
})

export const { StatusPanel: EditorTreeStatusPanel } = createStatusPanel({
  checkedModel: editorCheckedModel,
  flatTree$: treeModel.flatTree$,
  searchNodeInfo$: searchModel.searchNode$,
})

const selectedModels$ = selectedModelsService.models$.map(
  models => models?.map(({ viewerId }) => viewerId) || [],
)

const modelStoreService = createModelStoreService((node, viewerRef) =>
  getViewerId({
    node,
    viewerRef,
    nodes: classifierService.nodes$.getState(),
    getNodeFunc: getNode,
    validateModelTypes,
  }),
)

const { versionDates$, versions$, removeVersion } = createVersionHistoryStore({
  fetchDates: smApi.UserClassifier.fetchVersionDates.doneData,
  fetchVersions: smApi.UserClassifier.fetchVersionByDate.done.map(
    ({ params, result: { versions } }) => ({
      versionDate: params.versionDate,
      versions,
    }),
  ),
  fetchNamedVersionDates: smApi.UserClassifier.fetchNamedVersions.doneData.map(
    ({ versions }) => ({
      versionDates: [
        ...new Set(
          versions.map(version => fromDateTimeToDate(version.createdDate)),
        ),
      ],
    }),
  ),
  fetchNamedVersions: smApi.UserClassifier.fetchNamedVersions.doneData.map(
    ({ versions }) =>
      versions.map(version => ({
        versionDate: fromDateTimeToDate(version.createdDate),
        versions: versions
          .filter(vrs => compareDates(vrs.createdDate, version.createdDate))
          .sort((a, b) => sortByDate(a.createdDate, b.createdDate)),
      })),
  ),
  versionNameChanged: merge([
    smApi.UserClassifier.renameVersion.doneData,
    smApi.UserClassifier.removeVersionName.doneData,
  ]).map(data => ({
    versionDate: fromDateTimeToDate(data.createdDate),
    version: data,
  })),
})

const versionHistoryPending$ = combine(
  [
    smApi.UserClassifier.fetchNamedVersions.defaultContext.pending$,
    smApi.UserClassifier.fetchVersionDates.defaultContext.pending$,
  ],
  pendings => pendings.some(Boolean),
)

sample({
  source: currentUserClassifier$,
  clock: merge([
    smApi.UserClassifier.renameVersion.doneData,
    smApi.UserClassifier.removeVersionName.doneData,
  ]),
  fn: (estimation, versionData) => ({ estimation, versionData }),
}).watch(({ estimation, versionData }) => {
  if (estimation?.version === versionData.version) {
    smApi.UserClassifier.fetch.defaultContext(estimation)
  }
})

const dependencyRootNodes$ = sample({
  source: classifierService.nodes$,
  clock: dependencyTreeModel.flatTree$,
  fn: (nodes, flatTree) =>
    flatTree
      .filter(isApiFlatNode)
      .filter(({ path }) => path.length === 1)
      .map(({ ref }) => getNode(nodes, ref))
      .filter(isNotEmpty),
})

export const expandVersionModel = createExpandModel()

const entityUpdated = sample({
  clock: notificationService.message
    .filter({ fn: smApi.NotificationEvent.Update.is })
    .map(prop('payload'))
    .filter({ fn: smApi.UserClassifier.is }),
  source: currentUserClassifier$,
  fn: (currentCls, updatedCls) =>
    updatedCls.id === currentCls?.id ? updatedCls : null,
})

const fetchDependenciesReset = createEvent<void>()

const { sourceClassifiersLoaded$ } = createFetchDependenciesService({
  entity$: currentUserClassifier$,
  fetchedClassifiers: classifierService.dependencies.update.doneData.map(
    ({ BaseClassifierNode, UserClassifierNode, AssemblyClassifierNode }) => [
      ...Object.values(BaseClassifierNode),
      ...Object.values(UserClassifierNode),
      ...Object.values(AssemblyClassifierNode),
    ],
  ),
  entityUpdated,
  reset: fetchDependenciesReset,
  nodes$: classifierService.nodes$,
})

createViewerDisplayModel({
  currentEntity$: currentUserClassifier$,
  nodes$: classifierService.nodes$,
  searchModel,
  getViewerId: (node, viewerRef) =>
    getViewerId({
      node,
      viewerRef,
      nodes: classifierService.nodes$.getState(),
      getNodeFunc: getNode,
      validateModelTypes,
    }),
  getClsModels: (clsId, clsVersion) => {
    const dependenciesWithModels = modelStoreService.dependenciesWithModels$.getState()
    const models =
      dependenciesWithModels
        .find(
          ({ classifier }) =>
            classifier.id === clsId && classifier.version === clsVersion,
        )
        ?.models.map(m => adapter(m)) || null

    if (!models) {
      throw new Error(
        `Models not found in classifier id: ${clsId}, version: ${clsVersion}`,
      )
    }

    // Now we are sure that model arr contain  only bim360 or Gstation models
    validateModelTypes(models)

    return models
  },
})

// Инкапсулировать логику в common после GT-984 - Front: Исправить проблему: "circular dependencies" в репозиториях
message
  .filter({ fn: smApi.NotificationEvent.Update.is })
  .map(prop('payload'))
  .filter({ fn: smApi.BimModel.is })
  .filter({
    fn: model =>
      model.modelType === 'ForgeModel' && model.status === 'ImportCompleted',
  })
  .watch(() => {
    // Если делать через sample или guard c currentUserClassifier$ то ноды в проводнике перестают отображаться
    const cls = currentUserClassifier$.getState()
    if (!cls) {
      return
    }
    smApi.UserClassifier.fetchFlatListDependencies.defaultContext.submit({
      classifierVersion: cls.version,
      classifierId: cls.id,
    })
  })

export const UserClassifierEditorPage = React.memo(
  ({
    currentClassifier,
    isCurrentVersion,
  }: {
    currentClassifier: UserClassifierNode | null
    isCurrentVersion: boolean
  }) => {
    const {
      InclusionFilterButton,
      InclusionFilterContent,
    } = useInclusionFilter()

    const userInfo = useStore(userInfo$)
    const versionHistoryPending = useStore(versionHistoryPending$)

    const fetchFlatListDependenciesPending = useStore(
      smApi.UserClassifier.fetchFlatListDependencies.defaultContext.pending$,
    )
    const fetchFlatListItemsPending = useStore(
      smApi.UserClassifier.fetchFlatListItems.defaultContext.pending$,
    )
    const inCreateNode = useStore(treeModel.inCreateNode$)

    const versionDates = useStore(versionDates$)
    const versions = useStore(versions$)
    const renameUserClassifierPending = useStore(
      smApi.UserClassifier.rename.defaultContext.pending$,
    )
    const fetchListProjectPending = useStore(
      smApi.Project.fetchList.defaultContext.pending$,
    )

    const { keycloak } = useKeycloak()

    const selectedModels = useStore(selectedModels$)
    const [selectViewerRefs, setSelectViewerRefs] = React.useState<
      Record<string, string[]>
    >({})

    const currentProject = useStore(currentProject$)
    const viewerDerivatives = useStore(viewerDerivatives$)

    const loadModelByUrl = !!(currentProject?.sourceType === 'GStation')

    const fetchToken = React.useCallback(async (): Promise<smApi.ForgeToken> => {
      if (loadModelByUrl) {
        return {
          accessToken: keycloak.token!,
          expiresAt: keycloak.tokenParsed!.exp!,
        }
      }

      const token = await fetchForgeToken()

      return token
    }, [keycloak, loadModelByUrl])

    const getViewerModelUrl = React.useCallback(
      (idFromUrn: string) => {
        let url = ''
        viewerDerivatives?.files.forEach(({ derivatives, urn }) => {
          // eslint-disable-next-line prefer-named-capture-group
          const match = urn.match(/urn:gstation\.dm\.file:(\d+)/u)

          if (match?.[1] === idFromUrn) {
            url = derivatives.find(d => d.type === '3D')?.view || ''
          }
        })

        if (!url) {
          throw new Error(`Url not found for model with urn: ${idFromUrn}`)
        }
        return url
      },
      [viewerDerivatives?.files],
    )

    React.useEffect(() => {
      const subscription = searchModel.resetSearchNode.watch(() => {
        setSelectViewerRefs({})
      })

      return () => {
        subscription.unsubscribe()
      }
    }, [])

    React.useEffect(
      () => () => modelStoreService.resetDependenciesWithModels(),
      [],
    )

    React.useEffect(
      () => () => {
        classifierService.reset()
        resetBimFileIds()
        fetchDependenciesReset()
      },
      [],
    )

    const id = currentClassifier?.id

    React.useEffect(() => {
      if (id) {
        subscriptions.subscribeUserClassifier({ id: id! })
      }
      return () => {
        if (id) {
          subscriptions.unsubscribeUserClassifier({ id: id! })
        }
      }
    }, [id])

    React.useEffect(() => {
      filter.setStorageKey(currentClassifier ? `${currentClassifier.id}` : null)

      return filter.reset
    }, [currentClassifier])

    const createRootGroup = React.useCallback(() => {
      treeModel.setInCreateRootNode()
    }, [])

    const { readonlyMode } = useReadonlyMode()

    const [openVersionHistory, setOpenVersionHistory] = React.useState(false)

    const {
      NamedVersionsSwitch,
      namedVersions,
      closeNamedVersions,
    } = useNamedVersions({
      getNamedVersions: async () =>
        !!(await smApi.UserClassifier.fetchNamedVersions.defaultContext({
          classifierId: currentClassifier!.id,
        })),
      onDisable: () => {
        expandVersionModel.resetExpanded()
        smApi.UserClassifier.fetchVersionDates.defaultContext({
          classifierId: currentClassifier!.id,
        })
      },
      onActive: () =>
        versionDates && expandVersionModel.expandAll(versionDates.versionDates),
      disabled: versionHistoryPending,
    })

    const onOpenVersionHistory = React.useCallback(async () => {
      await smApi.UserClassifier.fetchVersionDates.defaultContext({
        classifierId: currentClassifier!.id,
      })

      setOpenVersionHistory(true)
    }, [currentClassifier])

    const {
      ChangeVersionNameDialog,
      setChangeNameDialog,
      changeNameDialog,
    } = useVersionName<smApi.VersionData>({
      onSubmitChangeName: async ({ name, version }) =>
        !!(await smApi.UserClassifier.renameVersion.defaultContext({
          classifierId: currentClassifier!.id,
          classifierVersion: version,
          versionName: name,
        })),
    })

    const { ContextMenu, setCtxMenu } = useContextMenu<smApi.VersionData>([
      {
        title: 'Перейти к версии',
        onClick: ({ version }) => {
          window.open(
            `${window.location.origin}/user-classifiers/${
              currentClassifier!.id
            }/version/${version}`,
            '_blank',
          )
        },
        show: item => item.version !== currentClassifier!.version,
      },
      {
        title: 'Переименовать',
        onClick: item => {
          setChangeNameDialog(item)
        },
        show: item => !!item.name,
      },
      {
        title: 'Присвоить имя',
        onClick: item => {
          setChangeNameDialog(item)
        },
        show: item => !item.name,
      },
      {
        title: 'Удалить название',
        onClick: item => {
          smApi.UserClassifier.removeVersionName
            .defaultContext({
              classifierId: currentClassifier!.id,
              classifierVersion: item.version,
            })
            .then(version => {
              if (namedVersions) {
                removeVersion({
                  versionDate: fromDateTimeToDate(version.createdDate),
                  version,
                })
              }
            })
        },
        show: item => !!item.name,
      },
    ])

    const { NavbarDropDown, opened } = useNavbar({
      navModules: getModulesLinkFromEnv(envLinks),
    })

    const editorChecked = useStore(editorCheckedModel.checked$)
    const dependencyChecked = useStore(dependencyCheckedModel.checked$)

    const onCloseVersionHistory = React.useCallback(() => {
      if (namedVersions) {
        closeNamedVersions()
      }
      setOpenVersionHistory(false)
    }, [closeNamedVersions, namedVersions])

    const onRenameEntity = (value: string) => {
      if (!currentClassifier) {
        return
      }
      smApi.UserClassifier.rename.defaultContext.submit({
        name: value,
        parentFolderId: currentClassifier.parentFolderId,
        id: currentClassifier.id,
        version: currentClassifier.version,
      })
    }

    const fetchDependencyModels = React.useCallback(
      (params: { id: number; version: number }) => {
        smApi.DependencyWithModels.getClassifierDependencyModels.defaultContext(
          params,
        )
      },
      [],
    )

    const projectBadge = (
      <ProjectBadge
        projectName={currentProject?.name || ''}
        loading={fetchListProjectPending}
      />
    )

    if (!currentProject) {
      return null
    }

    return (
      <>
        <DragLayer
          editorCheckedCount={Object.keys(editorChecked).length}
          depsCheckedCount={Object.keys(dependencyChecked).length}
        />
        <Box display='flex' flexDirection='column' height='100%'>
          <Header
            onLogout={() => keycloak.logout()}
            title={currentClassifier?.name}
            brandSecondary={
              <BrandSecondaryWrapper>{projectBadge}</BrandSecondaryWrapper>
            }
            titleSecondary={
              <>
                <VersionButton
                  onClick={onOpenVersionHistory}
                  isActualVersion={isCurrentVersion}
                  currentVersion={currentClassifier!.version}
                  versionCreated={currentClassifier!.versionCreatedDate}
                  versionName={currentClassifier!.versionName}
                  title={currentClassifier?.name || ''}
                  pending={versionHistoryPending}
                  onRename={onRenameEntity}
                  renamePending={renameUserClassifierPending}
                />
                <Box ml={1} flexGrow={1}>
                  <Tags />
                </Box>
              </>
            }
            brand={
              <Tooltip
                enterDelay={400}
                title={opened ? '' : 'Выйти к списку инспекций'}
              >
                <BrandContainer
                  data-test-id='backToExplorer'
                  to={
                    currentProject ? `/?projectUrn=${currentProject?.urn}` : '/'
                  }
                  active={opened}
                >
                  <HeaderLogoWrap>
                    <Logo />
                  </HeaderLogoWrap>
                  <BrandTitle>Set-management</BrandTitle>
                  {NavbarDropDown}
                </BrandContainer>
              </Tooltip>
            }
            userInfo={userInfo}
            onProfileClick={() => goals.showUserMenu()}
          />

          <PageContentContainer data-test-id='UserClassifierEditor'>
            <Resizable
              foldedPanelWidthPx={50}
              storageKey='UserClassifierEditorPanels'
            >
              <ResizablePanel
                header={
                  <Box
                    ml='-16px'
                    maxWidth='calc(100% - 32px)'
                    style={{ overflow: 'hidden' }}
                  >
                    <ModelSelect
                      dependencyRootNodes$={dependencyRootNodes$}
                      currentEntity$={currentUserClassifier$}
                      fetchDependencyModels={fetchDependencyModels}
                      searchedBimNode$={searchedBimNode$}
                      modelStoreService={modelStoreService}
                      getViewerId={(node, viewerRef) =>
                        getViewerId({
                          node,
                          viewerRef,
                          nodes: classifierService.nodes$.getState(),
                          getNodeFunc: getNode,
                          validateModelTypes,
                        })
                      }
                    />
                  </Box>
                }
                foldedTitle='Просмотр модели'
                onOpened={() => {
                  goals.expandViewer()
                  logEventEditor('EXPAND_VIEWER', { entity: 'Classifier' })
                }}
                onClosed={() => {
                  goals.foldViewer()
                  logEventEditor('FOLD_VIEWER', { entity: 'Classifier' })
                }}
                data-test-id='ModelViewerSection'
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyVH
              >
                <forgeViewer.ViewerComponent
                  selectedModels={selectedModels}
                  resetSearchNode={searchModel.resetSearchNode}
                  selectViewerElements={selectViewerRefs}
                  resetSelectedModels={selectedModelsService.reset}
                  loadModelByUrl={loadModelByUrl}
                  getModelUrl={getViewerModelUrl}
                  fetchToken={fetchToken}
                />
              </ResizablePanel>

              <ResizablePanel
                title='Зависимости'
                header={
                  <>
                    <SeparatorList>
                      <Tooltip title='Добавить модель(и)' placement='top'>
                        <IconButton
                          onClick={openExplorer}
                          data-test-id='openDependencyList'
                          data-test-ui-type='CreateButton'
                          disabled={readonlyMode.enabled}
                        >
                          <PlusCircle color='rgba(53, 59, 96, 0.5)' />
                        </IconButton>
                      </Tooltip>

                      {InclusionFilterButton}
                    </SeparatorList>
                  </>
                }
                headerSecondary={<ModelManageMenu />}
                footer={<DependenciesStatusPanel />}
                onOpened={() => {
                  goals.expandDependencies()
                }}
                onClosed={() => {
                  goals.foldDependencies()
                }}
                data-test-id='DependenciesSection'
                body={InclusionFilterContent}
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyDN
              >
                <Box
                  width='100%'
                  height='100%'
                  display='flex'
                  flexDirection='column'
                >
                  <TreeLoader
                    loading={fetchFlatListDependenciesPending}
                    text='Идет загрузка...'
                  />
                  <Cover
                    open={
                      currentClassifier?.sourceClassifiers?.length === 0 &&
                      !fetchFlatListDependenciesPending
                    }
                    icon={
                      <Substrate>
                        <WithCursorIcon>
                          <PlusCircle color='#DADADA' width={45} height={45} />
                        </WithCursorIcon>
                      </Substrate>
                    }
                    title={
                      <>
                        Добавьте Модели в “Зависимости”
                        <br /> для начала работы
                      </>
                    }
                  />
                  <DependencyTreeWrap
                    dynamicGroupsConditions={{}}
                    currentUserClassifier$={currentUserClassifier$}
                    dependencyCheckedModel={dependencyCheckedModel}
                    dependencyExpandModel={dependencyExpandModel}
                    dependencyTreeModel={dependencyTreeModel}
                    fetchAllInclusion={fetchAllInclusion}
                    filteredFlatTree$={filteredFlatTree$}
                    inclusionStore$={inclusionStore$}
                    loadOnceNodeInclusion={loadOnceNodeInclusion}
                    nodes$={classifierService.nodes$}
                    searchModel={searchModel}
                    inclusionStatusWithTranscript={
                      inclusionStatusWithTranscript
                    }
                    sourceClassifiersLoaded$={sourceClassifiersLoaded$}
                    searchSourceData$={searchSourceModel.searchSourceData$}
                    selectForgeRefs={setSelectViewerRefs}
                  />
                  <SelectDependencies
                    ClassifiersTree={ClassifiersTree}
                    ModelsTree={ModelsTree}
                    brand={projectBadge}
                  />
                </Box>
              </ResizablePanel>

              <ResizablePanel
                title='Мой классификатор'
                header={
                  <Tooltip
                    title='Добавить папку в Мой классификатор'
                    placement='top'
                  >
                    <IconButton
                      onClick={() => createRootGroup()}
                      data-test-id='addRootGroup'
                      data-test-ui-type='CreateButton'
                      disabled={readonlyMode.enabled}
                    >
                      <FolderWithPlus color='rgba(53, 59, 96, 0.5)' />
                    </IconButton>
                  </Tooltip>
                }
                onOpened={() => {
                  goals.expandClassifier()
                }}
                onClosed={() => {
                  goals.foldClassifier()
                }}
                data-test-id='ElementsSection'
                footer={<EditorTreeStatusPanel />}
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyDN
              >
                <TreeLoader
                  loading={fetchFlatListItemsPending}
                  text='Идет загрузка...'
                />
                <Cover
                  open={
                    currentClassifier?.children.length === 0 &&
                    !fetchFlatListItemsPending &&
                    !inCreateNode
                  }
                  icon={
                    <Substrate>
                      <WithCursorIcon>
                        <FolderWithPlus
                          color='#DADADA'
                          width={45}
                          height={45}
                        />
                      </WithCursorIcon>
                    </Substrate>
                  }
                  title={
                    <>
                      Создайте желаемую структуру <br /> папок и перенесите в
                      них необходимые <br />
                      элементы из зависимостей
                    </>
                  }
                />
                <EditorTreeWrap
                  searchModel={searchModel}
                  selectViewerRefs={setSelectViewerRefs}
                  dependenciesWithModels$={
                    modelStoreService.dependenciesWithModels$
                  }
                />
              </ResizablePanel>
            </Resizable>

            {/* <EditorSection
            header={
                <Box display='flex' alignItems='center'>
                  <WithSumIcon icon={<Folder />} />
                  {selectedGroup?.name}
                </Box>
            }
            data-test-id='GroupSettingsSection'
            foldTestId='rollUpGroupSettings'
            lsDataKey='groupSettings'
          >
            <GroupSettings selectedGroup={selectedGroup} />
          </EditorSection> */}
          </PageContentContainer>
          <VersionHistory
            open={openVersionHistory}
            onClose={onCloseVersionHistory}
            onRedirectToLast={() => {
              window.location.pathname = `/user-classifiers/${
                currentClassifier!.id
              }`
            }}
            isCurrentVersion={isCurrentVersion}
            beforeList={NamedVersionsSwitch}
            versionList={
              <VersionList
                onMenuClick={setCtxMenu}
                currentVersion={currentClassifier!.version}
                dates={versionDates}
                versions={versions}
                fetchVersions={async versionDate => {
                  await smApi.UserClassifier.fetchVersionByDate.defaultContext({
                    classifierId: currentClassifier!.id,
                    versionDate,
                  })
                }}
                anotherListOpened={namedVersions}
                contextMenu={!changeNameDialog && ContextMenu()}
                openVersionHistory={openVersionHistory}
                expandModel={expandVersionModel}
              />
            }
          />
          {ChangeVersionNameDialog()}
        </Box>
      </>
    )
  },
)

UserClassifierEditorPage.displayName = 'UserClassifierEditorPage'
