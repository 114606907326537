import React from 'react'

import { useStore } from 'effector-react'

import { Store } from 'effector'

import { useSnackbar } from 'notistack'

import { getNode, Nodes } from '@gmini/common/lib/classifier-service'
import {
  BimRef,
  isBimNode,
  isReferenceNode,
  ReferenceNode,
  AssemblyClassifierNode,
  BaseClassifierNode,
} from '@gmini/common/lib/classifier-service/Node'

import { NodeLayout } from '@gmini/common/lib/classifier-editor/DependencyTree/NodeLayout'

import { DependencyTreeModel } from '@gmini/common/lib/classifier-editor/DependencyTree/model/treeModel'

import { ExpandModel } from '@gmini/common/lib/classifier-editor/DependencyTree/model/expandModel'

import { pendingMapClassifier$ } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/pendingModel'

import { TreeLoader } from '@gmini/common/lib/classifier-editor/TreeLoader'

import {
  DependencyTree,
  SearchModel,
} from '@gmini/common/lib/classifier-editor'

import { CheckedModel } from '@gmini/common/lib/classifier-editor/CheckedModel'
import { isNotEmpty } from '@gmini/utils'

import { NumCycleContainer } from '@gmini/common/lib/components'

import {
  getViewerId,
  validateModelTypes,
} from '@gmini/common/lib/classifier-editor/Common'

import { searchSourceModel } from './model/searchSourceModel'

type AssemblyTreeWrapProps = {
  searchModel: SearchModel
  assemblyExpandModel: ExpandModel
  currentAssembly: AssemblyClassifierNode | null
  nodes$: Store<Nodes>
  assemblyTreeModel: DependencyTreeModel
  assemblyCheckedModel: CheckedModel
  disableOperations?: boolean
  currentDependencies: BaseClassifierNode[]
  selectViewerRefs: (value: Record<string, string[]>) => void
}

export const AssemblyTreeWrap = ({
  searchModel,
  assemblyExpandModel,
  assemblyTreeModel,
  currentAssembly,
  nodes$,
  assemblyCheckedModel,
  disableOperations,
  currentDependencies,
  selectViewerRefs,
}: AssemblyTreeWrapProps) => {
  const { searchMatched$ } = searchModel
  const nodes = useStore(nodes$)
  const pendingMap = useStore(pendingMapClassifier$)

  const findAnywhere = React.useCallback(
    (
      ref:
        | BimRef
        | Pick<ReferenceNode, 'id' | 'type'>
        | Pick<AssemblyClassifierNode, 'id' | 'type'>,
      path: string[],
    ) => {
      let node = getNode(nodes, ref)
      if (node && isReferenceNode(node)) {
        node = getNode(nodes, node.element)
      }

      if (node?.type === 'AssemblyClassifierNode' && node.assemblyModelRef) {
        node = getNode(nodes, node.assemblyModelRef.element)
      }

      if (node && isBimNode(node)) {
        searchModel.setSearchNode({ node, path })
        if (node.viewerRefs) {
          const bimNode = node
          selectViewerRefs(
            node.viewerRefs.reduce<Record<string, string[]>>(
              (acc, viewerRef) => {
                const viewerId = getViewerId({
                  node: bimNode,
                  viewerRef,
                  nodes,
                  getNodeFunc: getNode,
                  validateModelTypes,
                })
                return {
                  ...acc,
                  [viewerId]: [viewerRef.externalId],
                }
              },
              {},
            ),
          )
        }
      }
    },
    [nodes, searchModel, selectViewerRefs],
  )

  const onPending = React.useCallback((key: string) => !!pendingMap[key], [
    pendingMap,
  ])

  const { enqueueSnackbar } = useSnackbar()

  const notify = React.useCallback(
    (reason: string) => {
      enqueueSnackbar(reason, {
        variant: 'error',
      })
    },
    [enqueueSnackbar],
  )

  const dependenciesModels = currentDependencies
    .map(r => getNode(nodes, r))
    .flatMap(node => node?.children)
    .filter(isNotEmpty)
    .map(r => getNode(nodes, r))
    .filter(isNotEmpty)

  if (!currentAssembly) {
    return null
  }

  return (
    <>
      <TreeLoader />
      <DependencyTree
        dynamicGroupsConditions={{}}
        notify={notify}
        nodes$={nodes$}
        currentEntity={currentAssembly}
        treeModel={assemblyTreeModel}
        checkedModel={assemblyCheckedModel}
        expandModel={assemblyExpandModel}
        selectedFromOtherTreeCount={0}
        onFindAnywhere={findAnywhere}
        disableOperations={disableOperations}
        searchMatched$={searchMatched$}
        onPending={onPending}
        selectedPath={{ path: [] }}
        renderNodeLayout={({ node, nodeLayoutProps, path }) => {
          const nextProps = {
            ...nodeLayoutProps,
          }

          if (
            node.type !== 'UserClassifierGroupNode' &&
            node.type !== 'DynamicBimElementReferenceNode' &&
            node.type !== 'DynamicGeneratedGroupNode' &&
            node.type !== 'ReferenceNode' &&
            'source' in node &&
            (node.type === 'BimElementNode' || !node.total)
          ) {
            const depIdx = dependenciesModels.findIndex(ref =>
              node.source?.some(model => model.modelId === ref.element.id),
            )
            const ref = dependenciesModels[depIdx]

            const model = getNode(nodes, ref.element)
            nextProps.numCycle = (
              <NumCycleContainer
                onSearchSource={searchSourceModel.setSearchSourceData}
                dependencyData={{
                  displayNumber: `${depIdx + 1}`,
                  name: model?.name || '',
                  source: {
                    id: ref.parentClassifierId,
                    version: ref.parentClassifierVersion,
                  },
                }}
              />
            )
          }

          return <NodeLayout {...nextProps} />
        }}
        showModelVersion={false}
        searchSourceData$={searchSourceModel.searchSourceData$}
      />
    </>
  )
}
