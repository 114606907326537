import React from 'react'

import { useStore } from 'effector-react'

import { combine } from 'effector'

import { useSnackbar } from 'notistack'

import { getNode } from '@gmini/common/lib/classifier-service'

import { getBimNode } from '@gmini/common/lib/classifier-editor/Search/utils'

import {
  BimRef,
  isReferenceNode,
  NodeRef,
  ReferenceNode,
  UserClassifierGroupNodeRef,
} from '@gmini/common/lib/classifier-service/Node'

import { nodeToApiTypeMap } from '@gmini/common/lib/classifier-service/adapters'

import { createTreeModel } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/treeModel'

import { EditorTree } from '@gmini/common/lib/classifier-editor/ClassifierTree/EditorTree'
import { moveMultiplyFromOwn } from '@gmini/common/lib/classifier-editor/ClassifierTree/multiply/moveMultiplyFromOwn'
import { insertMultiplyFromDeps } from '@gmini/common/lib/classifier-editor/ClassifierTree/multiply/insertMultiplyFromDeps'

import {
  FlatNode,
  isApiFlatNode,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/createTree'

import { createExpandModel } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/expandModel'

import { dynamicGroupMode$ } from '@gmini/common/lib/classifier-editor/ClassifierTree/dynamicGroupMode'

import { pendingMapClassifier$ } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/pendingModel'

import {
  removeAllNodes,
  removeNode,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/model/removeModel'

import { validateMoveFromDeps } from '@gmini/common/lib/classifier-editor/validate/validate-move'

import {
  updateChecked,
  ViewerCheckedMap,
  resetChecked,
  SearchModel,
  ModelStoreService,
} from '@gmini/common/lib/classifier-editor'

import {
  getViewerId,
  validateModelTypes,
} from '@gmini/common/lib/classifier-editor/Common'

import {
  TreeLoader,
  operationsPending$,
} from '@gmini/common/lib/classifier-editor/TreeLoader'

import * as smApi from '@gmini/sm-api-sdk'

import { NodeLayout } from '@gmini/common/lib/classifier-editor/ClassifierTree/NodeLayout/NodeLayout'

import { resetShowMode } from '@gmini/common/lib/forge-viewer/model/selectModel'

import { currentUserClassifier$ } from '../../CurrentUserClassifier'
import { classifierService } from '../../../services/userClassifierService'
import {
  dependencyCheckedItems$,
  editorCheckedModel,
} from '../model/checkedModel'

import { selectedForgeElements$ } from '../core/viewerSelection/viewerSelection'
import { searchSourceModel } from '../model/searchSourceModel'

export const treeModel = createTreeModel({
  currentUserClassifier$,
  nodes$: classifierService.nodes$,
  onCreatedNode: smApi.UserClassifierGroup.create.done,
  dynamicMode$: dynamicGroupMode$,
})

const FILTER_LIMIT = 5000

const expandModel = createExpandModel()

// Для работы с чекнутыми элементами во вьювере
// TODO После рефакторинга логики fromEditorToViewer выпилить
editorCheckedModel.checked$.updates.watch(checkedMap => {
  if (Object.keys(checkedMap).length > 0) {
    const checkedMap = combine(
      { tree: treeModel.flatTree$, checked: editorCheckedModel.checked$ },
      ({ checked, tree }) => {
        const keys = Object.keys(checked)

        return tree
          .filter(isApiFlatNode)
          .filter(item =>
            keys.some(k => item.path.join(':') === k && checked[k]),
          )
          .reduce(
            (acc, { ref }) =>
              ref
                ? {
                    ...acc,
                    [ref.type + ref.id]: ref,
                  }
                : acc,
            {} as ViewerCheckedMap,
          )
      },
    )
    updateChecked({
      path: 'Own',
      checkedMap: checkedMap.getState(),
    })
  } else {
    resetChecked('Own')
    resetShowMode()
  }
})

export const EditorTreeWrap = ({
  searchModel,
  selectViewerRefs,
  dependenciesWithModels$,
}: {
  searchModel: SearchModel
  selectViewerRefs: (value: Record<string, string[]>) => void
  dependenciesWithModels$: ModelStoreService['dependenciesWithModels$']
}) => {
  const { searchMatched$, searchNode$ } = searchModel

  const currentUserClassifier = useStore(currentUserClassifier$)
  const depsCheckedList = useStore(dependencyCheckedItems$)
  const nodes = useStore(classifierService.nodes$)
  const searchNode = useStore(searchNode$)
  const pendingMap = useStore(pendingMapClassifier$)
  const operationsPending = useStore(operationsPending$)
  const selectedForgeElements = useStore(selectedForgeElements$)

  const findAnywhere = React.useCallback(
    (ref: BimRef | Pick<ReferenceNode, 'id' | 'type'>, path: string[]) => {
      let node = getNode(nodes, ref)
      if (node && isReferenceNode(node)) {
        node = getNode(nodes, node.element)
      }

      if (!node) {
        return
      }

      searchModel.setSearchNode({ node, path })

      const bimNode = getBimNode(node, nodes)

      if (bimNode && bimNode.viewerRefs) {
        selectViewerRefs(
          bimNode.viewerRefs.reduce(
            (acc: Record<string, string[]>, viewerRef) => {
              const viewerId = getViewerId({
                node: bimNode,
                viewerRef,
                nodes,
                getNodeFunc: getNode,
                validateModelTypes,
              })
              return {
                ...acc,
                [viewerId]: [viewerRef.externalId],
              }
            },
            {},
          ),
        )
      }
    },
    [nodes, searchModel, selectViewerRefs],
  )

  const removeRef = React.useCallback(
    (ref: UserClassifierGroupNodeRef | Pick<ReferenceNode, 'type' | 'id'>) => {
      removeNode(ref, currentUserClassifier!)
    },
    [currentUserClassifier],
  )

  const deleteSelected = React.useCallback(
    (items: NodeRef[]) => {
      const { id, version } = currentUserClassifier!
      removeAllNodes({
        id,
        version,
        items: items.map(item => ({
          ...item,
          type: nodeToApiTypeMap[item.type] as
            | smApi.BimReference['type']
            | smApi.UserClassifierGroup['type'],
        })),
      })
    },
    [currentUserClassifier],
  )

  const onPending = React.useCallback((key: string) => !!pendingMap[key], [
    pendingMap,
  ])

  const allowToCreate = React.useCallback(
    (node: FlatNode) => node.ref.type !== 'UserClassifierGroupWithElementsNode',
    [],
  )
  const { enqueueSnackbar } = useSnackbar()

  const notify = React.useCallback(
    (reason: string) => {
      enqueueSnackbar(reason, {
        variant: 'error',
      })
    },
    [enqueueSnackbar],
  )

  const disabledInsertFilteredElements = (node: FlatNode) => {
    const overLimit =
      (selectedForgeElements?.reduce(
        (acc, next) => acc + next.elementForgeExternalIds.length,
        0,
      ) || 0) >= FILTER_LIMIT

    if (node.ref.type === 'UserClassifierGroupWithGroupsNode') {
      return 'Нельзя вставить элементы в папку с папками'
    } else if (overLimit) {
      return `Невозможно добавить более ${FILTER_LIMIT} элементов`
    }

    return ''
  }

  const onInsertFilteredElements = React.useCallback(
    (ref: UserClassifierGroupNodeRef) => {
      if (
        selectedForgeElements?.length &&
        (ref.type === 'UserClassifierGroupWithElementsNode' ||
          ref.type === 'UserClassifierEmptyGroupNode')
      ) {
        smApi.UserClassifier.createRefsFromExternalIds.defaultContext({
          id: currentUserClassifier!.id,
          version: currentUserClassifier!.version,
          parentGroupId: ref.id,
          items: selectedForgeElements,
        })
      }
    },
    [currentUserClassifier, selectedForgeElements],
  )

  if (!currentUserClassifier) {
    return null
  }

  return (
    <>
      <TreeLoader />
      <EditorTree
        notify={notify}
        isSpecialNode={() => false}
        dynamicMode$={dynamicGroupMode$}
        dynamicGroupsConditions={{}}
        nodes$={classifierService.nodes$}
        currentUserClassifier={currentUserClassifier}
        treeModel={treeModel}
        checkedModel={editorCheckedModel}
        expandModel={expandModel}
        selectedFromOtherTreeCount={depsCheckedList.length}
        onSubmitCreating={(name, { parentNodeRef }) => {
          const parentGroupId = parentNodeRef ? parentNodeRef.id : undefined

          smApi.UserClassifierGroup.create.defaultContext.submit({
            classifierId: currentUserClassifier.id,
            parentClassifierVersion: currentUserClassifier.version,
            parentGroupId,
            name,
          })
        }}
        onCancelCreating={() => {
          treeModel.setInCreateNode(null)
        }}
        setInCreateNode={n => treeModel.setInCreateNode(n)}
        onSubmitEditing={(newName, node) => {
          smApi.UserClassifierGroup.rename.defaultContext.submit({
            classifierId: currentUserClassifier.id,
            parentClassifierVersion: currentUserClassifier.version,
            parentGroupId: node.parentGroupId,
            id: node.id,
            name: newName,
          })
        }}
        hideCtxMenu={operationsPending}
        onMoveItems={({ target, items }) => {
          moveMultiplyFromOwn({
            currentClassifier: currentUserClassifier,
            items,
            nodes$: classifierService.nodes$,
            target,
          })
        }}
        onInsertFromOtherTree={({ target }) => {
          insertMultiplyFromDeps({
            currentClassifier: currentUserClassifier,
            dependenciesCheckedItems: depsCheckedList,
            nextParentNode: target,
            nodes$: classifierService.nodes$,
          })
        }}
        validateInsertFromOtherTree={({ target, nestingLevel }) =>
          validateMoveFromDeps({
            items: depsCheckedList,
            nodes,
            targetNode: target,
            nestingLevel,
            dynamicMode: false,
          })
        }
        onFindAnywhere={findAnywhere}
        onDelete={removeRef}
        onDeleteSelected={deleteSelected}
        searchNode={searchNode}
        searchMatched$={searchMatched$}
        onPending={onPending}
        allowCreate={allowToCreate}
        selectedPath={{ path: [] }}
        dependenciesWithModels$={dependenciesWithModels$}
        onSearchSource={searchSourceModel.setSearchSourceData}
        disabledInsertFilteredElements={disabledInsertFilteredElements}
        allowInsertFilteredElements={() => !!selectedForgeElements?.length}
        onInsertFilteredElements={onInsertFilteredElements}
        renderNodeLayout={({ node, nodeLayoutProps, path }) => {
          const nextProps = {
            ...nodeLayoutProps,
          }

          return <NodeLayout {...nextProps} />
        }}
      />
    </>
  )
}
