import { combine, forward, sample } from 'effector'

import { prop } from 'ramda'

import {
  isApiFlatNode,
  resetChecked,
  updateChecked,
  ViewerCheckedMap,
} from '@gmini/common/lib/classifier-editor'

import { createDependencyModelUpdatedEvent } from '@gmini/common/lib/classifier-editor/dependencyModelUpdatedEvent'

import * as smApi from '@gmini/sm-api-sdk'

import { resetShowMode } from '@gmini/common/lib/forge-viewer/model/selectModel'
import { setExtensionsToLoad } from '@gmini/common/lib/forge-viewer/extensions'

import {
  assemblyCheckedModel,
  dependencyCheckedModel,
} from '../model/checkedModel'
import { currentAssembly$ } from '../../CurrentAssembly/CurrentAssembly'
import { notificationService } from '../../../services/notificationService'
import { classifierService } from '../../../services/userClassifierService'
import { assemblyTreeModel } from '../model/assemblyTreeModel'
import { dependencyTreeModel } from '../model/dependencyTreeModel'

const updated = notificationService.message
  .filter({ fn: smApi.NotificationEvent.Update.is })
  .map(prop('payload'))

// Для работы с чекнутыми элементами во вьювере
// TODO После рефакторинга логики fromEditorToViewer выпилить
assemblyCheckedModel.checked$.updates.watch(checkedMap => {
  if (Object.keys(checkedMap).length > 0) {
    const checkedMap = combine(
      {
        tree: assemblyTreeModel.flatTree$,
        checked: assemblyCheckedModel.checked$,
      },
      ({ checked, tree }) => {
        const keys = Object.keys(checked)

        return tree
          .filter(isApiFlatNode)
          .filter(item =>
            keys.some(k => item.path.join(':') === k && checked[k]),
          )
          .reduce(
            (acc, { ref }) =>
              ref
                ? {
                    ...acc,
                    [ref.type + ref.id]: ref,
                  }
                : acc,
            {} as ViewerCheckedMap,
          )
      },
    )
    updateChecked({
      path: 'Own',
      checkedMap: checkedMap.getState(),
    })
  } else {
    resetChecked('Own')
    resetShowMode()
  }
})

dependencyCheckedModel.checked$.updates.watch(checkedMap => {
  if (Object.keys(checkedMap).length > 0) {
    const checkedMap = combine(
      {
        tree: dependencyTreeModel.flatTree$,
        checked: dependencyCheckedModel.checked$,
      },
      ({ checked, tree }) => {
        const keys = Object.keys(checked)

        return tree
          .filter(isApiFlatNode)
          .filter(item =>
            keys.some(k => item.path.join(':') === k && checked[k]),
          )
          .reduce(
            (acc, { ref }) =>
              ref
                ? {
                    ...acc,
                    [ref.type + ref.id]: ref,
                  }
                : acc,
            {} as ViewerCheckedMap,
          )
      },
    )
    updateChecked({
      path: 'Source',
      checkedMap: checkedMap.getState(),
    })
  } else {
    resetChecked('Source')
    resetShowMode()
  }
})

forward({
  from: sample(
    currentAssembly$,
    updated.filter({ fn: smApi.BaseClassifier.is }),
    (currentUserClassifier, sourceClassifier) => ({
      currentUserClassifier,
      sourceClassifier,
    }),
  ).filterMap(({ currentUserClassifier, sourceClassifier }) => {
    if (
      currentUserClassifier?.sourceClassifiers.some(
        item =>
          item.type === 'BaseClassifierNode' && item.id === sourceClassifier.id,
      )
    ) {
      return currentUserClassifier
    }
  }),
  to: smApi.getSourceClassifiersVersions.submit,
})

dependencyCheckedModel.checked$.updates.watch(checkedMap => {
  if (Object.keys(checkedMap).length > 0) {
    assemblyCheckedModel.resetChecked()
  }
})

assemblyCheckedModel.checked$.updates.watch(checkedMap => {
  if (Object.keys(checkedMap).length > 0) {
    dependencyCheckedModel.resetChecked()
  }
})

//TODO вынести в конструктор
const modelDependencyUpdatedEvent = createDependencyModelUpdatedEvent({
  nodes$: classifierService.nodes$,
  currentEntity$: currentAssembly$,
  notification: notificationService.message,
})

modelDependencyUpdatedEvent.watch(({ clsId, clsVersion, modelFromEvent }) => {
  smApi.getSourceClassifiersVersions.submit({
    id: clsId,
    version: clsVersion,
  })
})

modelDependencyUpdatedEvent.watch(({ clsId, clsVersion }) => {
  smApi.DependencyWithModels.getAssemblyDependencyModels.defaultContext.submit({
    id: clsId,
    version: clsVersion,
  })
})

// guard({
//   source: combine([searchModel.searchNode$, currentAssembly$]).map(
//     ([searchNode, currentAssembly]) => {
//       if (searchNode && searchNode.type && currentAssembly) {
//         return {
//           elementId: searchNode.id,

//           // FIXME: избавиться от каста и исправить тип SearchNodeStore
//           elementType: nodeToApiTypeMap[searchNode.type] as
//             | 'BimElement'
//             | 'BimModel'
//             | 'BimCategory'
//             | 'BimFamily'
//             | 'BimStandardSize',
//           id: currentAssembly.id,
//           version: currentAssembly.version,
//         }
//       }

//       return null
//     },
//   ),
//   filter: Boolean,
//   target: executeNodeSearch.defaultContext.submit,
// })

// Загрузка расширений для вьювера
setExtensionsToLoad(['AutofocusExtension'])
