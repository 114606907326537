import { byInclusion$ } from '@gmini/common/lib/classifier-editor/DependencyTree/Filters/InclusionFilter/FilterModel'
import { createInclusionModel } from '@gmini/common/lib/classifier-editor/DependencyTree/Inclusion'

import { notificationService } from '../../../services/notificationService'
import { classifierService } from '../../../services/userClassifierService'

import { currentUserClassifier$ } from '../../CurrentUserClassifier'

import { dependencyExpandModel } from './dependencyExpandModel'
import { dependencyTreeModel } from './dependencyTreeModel'

export const {
  fetchAllInclusion,
  loadOnceNodeInclusion,
  inclusionStore$,
  filteredFlatTree$,
} = createInclusionModel({
  flatTree$: dependencyTreeModel.flatTree$,
  expanded$: dependencyExpandModel.expanded$,
  currentEntity$: currentUserClassifier$,
  nodes$: classifierService.nodes$,
  notification: notificationService.message,
  inclusionFilterStatus$: byInclusion$,
})
